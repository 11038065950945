import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { withAPISubscription } from '../../../hoc';
import { commentDemande } from '../../../actions/demande';
import CommentListWithInput from '../CommentListWithInput';
import CommentList from '../CommentList';
import DemandeMenuInfo from './DemandeMenuInfo';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import Timeline from '@mui/lab/Timeline';
import InterMenuInfo from '../../List/Intervention/InterMenuInfo';
import Calls from '../../Calls/Calls';
import PrintFiles from '../../PrintFiles';
import { DDEM } from '@lba-dev/package.local-globals/docTypes';
import { fromJS } from 'immutable';
import { D_REF } from '@lba-dev/package.local-globals/demandeStatus';
import collections from '../../../constants/collections';

const styles = {
  padding: {
    padding: '12px',
  },
  lineHeight: {
    lineHeight: 'normal',
  },
  title: {
    marginBottom: 4,
    textTransform: 'uppercase',
    fontSize: '1rem',
  },
  a: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};

const formatHistory = (elem, histories = []) => {
  let newHist;
  newHist = [
    ...histories,
  ];
  if (elem.status === D_REF) {
    newHist = [
      ...histories,
    ];
  }
  if (elem?.date?.relance) {
    newHist = [
      ...histories,
    ];
  }
  if (elem.intervention) {
    newHist = [
      ...histories,
      {
        date: elem.intervention.date.ajout,
        text: `Ajoutée ${
          (elem.intervention.price &&
            `, Prix: ${elem.intervention.price / 100}`) ||
          ''
        }`,
        userId: elem?.intervention?.login?.ajout,
        osId: elem.intervention.id,
      },
      ...(elem?.intervention?.date?.verif
        ? [
          {
            date: elem.intervention.date.verif,
            text: 'Verifiée',
            userId: elem?.intervention?.login?.verif,
            osId: elem.intervention.id,
          },
        ]
        : []),
    ];
  }
  if (elem.devis) {
    newHist = [
      ...histories,
      {
        date: elem?.devis?.date?.ajout,
        text: `Devis ajouté${
          (elem.devis.price && `, Prix: ${elem.devis.price / 100}`) || ''
        }`,
        userId: elem?.devis?.login?.ajout,
        devisId: elem.devis.id,
      },
    ];
  }
  return Array.isArray(newHist)
    ? newHist.sort((a, b) => new Date(a.date) - new Date(b.date))
    : [];
};

const DemandeMenu = ({
  classes,
  findUserLogin,
  comment = (f) => f,
  comments = [],
  records = [],
  messages = [],
  histories = [],
  appelsClient = [],
  appelsArtisan = [],
  inter = [],
  elem,
}) => {
  const newHistories = formatHistory(elem, histories);
  const interHistories = formatHistory({ intervention: inter[0] });
  return (
    <Grid container spacing={2} className={classes.padding}>
      <Grid item xs={12} sm={3}>
        <Typography variant="h6" children="Commentaires" />
        <CommentListWithInput
          action={comment}
          comments={comments}
          commentKey="commentValue"
        />
      </Grid>
      {!!messages.length && (
        <Grid item xs={12} sm={3}>
          <Typography variant="h6" children="SMS" />
          <CommentList
            comments={messages}
            commentKey="content"
            empty="Pas de messages"
          />
        </Grid>
      )}
      {!!records.length && (
        records && <Calls
          sm={3}
          elem={records}
          title="Appels"
          keyUrl="fileUrl"
        />
      )}
      {!!newHistories.length && (
        <Grid item xs={12} sm={3}>
          <Typography variant="h6" children="Historique" />
          {newHistories.map((data, i) => (
            <DemandeMenuInfo
              {...data}
              key={i}
              findUserLogin={findUserLogin} />
          ))}
        </Grid>
      )}
      {!!appelsClient.length && (
        appelsClient && <Calls
          sm={3}
          elem={appelsClient}
          title="Historique d'appels"
          keyUrl="url"
        />
      )}
      {!!appelsArtisan.length && (
        appelsArtisan && <Calls
          sm={3}
          elem={appelsArtisan}
          title="Appels vers l'artisan"
          keyUrl="url"
        />
      )}
      {elem.reminders && (
        <Grid item xs={12} sm={3}>
          <Typography variant="h6" children="Historique de Rappels" />
          {elem.reminders.map((e, i) => (
            <React.Fragment key={i}>
              <Typography
                key={new Date(e.reminderDate).getTime()}
                variant="body1"
                className={classes.lineHeight}
              >
                {`${dayjs(e.date).format('LLL')} : Un rappel a été programmé
              le ${dayjs(e.reminderDate).format('L [à] HH[h]mm')}
              par ${findUserLogin(e.user)}`}
              </Typography>
            </React.Fragment>
          ))}
        </Grid>
      )}
      {!!interHistories.length &&
      <Grid item xs={12} md={4}>
        {[
          <Typography
            key={0}
            variant="h6"
            children="Historique intervention" />,
          <div key={7} className={classes.timeLineLimit}>
            {interHistories.map((data, i) => {
              const obj = {
                type: data.text,
                date: dayjs(data.date).format('L [à] HH[h]mm'),
                user: findUserLogin(data.userId),
              };
              return (
                <Timeline key={i} align="alternate">
                  <InterMenuInfo {...obj} />
                </Timeline>
              );
            })}
          </div>,
        ]}
      </Grid>
      }
      {!!elem?.dropbox?.length &&
        <Grid item xs={12} md={4}>
          <PrintFiles
            headline="Documents"
            type={DDEM}
            path="demandes"
            id={elem._id}
            underscoreId={elem._id}
            dropbox={fromJS(elem.dropbox || [])}
          />
        </Grid>}
    </Grid>
  );
};

const fetch = (api, { elem }) =>
  Promise.all([
    api.demandes.get(elem._id, {
      field: JSON.stringify({
        _id: 0,
        comments: 1,
        messages: 1,
      }),
    }),
    api.records.getAll({
      numbers: [elem.client.tel1],
      collrections: ['recordsRingOver', 'records'],
    }),
    api.history.getAll({ id: elem._id, name: 'demandeId' }),
    api.appels.getAll({
      query: JSON.stringify({
        demandeId: elem.id,
      }),
    }),
    elem?.createdFrom?.id &&
      elem?.createdFrom?.collection === collections.interventions &&
      api.interventions.getAll({
        query: JSON.stringify({ id: elem.createdFrom.id }),
        field: JSON.stringify({
          'login.ajout': 1,
          'date.ajout': 1,
          'login.verif': 1,
          'date.verif': 1,
        }),
      }),
  ]).then(([demande, records, histories, appels, inter]) => ({
    ...demande.body().data(),
    records: records.body().map((e) => ({
      ...e.data().record,
      transcription: e.data().transcription,
    })).sort(r => r.callStart),
    comment: (commentValue) => commentDemande(elem._id, commentValue),
    histories: histories.body().map((e) => e.data()),
    appelsClient:
      appels.body().map((e) => e.data()).filter(e => e.data?.choice),
    appelsArtisan:
      appels.body().map((e) => e.data()).filter(e => !e.data?.choice),
    ...(elem?.createdFrom?.id &&
    elem?.createdFrom?.collection === collections.interventions
      ? { inter: inter.body().map((e) => e.data()) }
      : []),
    elem,
  }));

const actions = [{ action: 'update_demandes' }];

const mapStateToProps = ({ users }) => {
  const findUserLogin = (userId, agent = '') => {
    const user = users.find(
      (u) =>
        u._id === userId ||
        u.ligne === agent.replace(/^0033/, 0) ||
        u.multiLignes.includes(agent.replace(/^0033/, 0))
    );
    return user ? user.login : 'Automatique';
  };
  return {
    findUserLogin,
  };
};

export default compose(
  withAPISubscription(fetch, actions, { deverse: true }),
  connect(mapStateToProps)
)(withStyles(DemandeMenu, styles));
