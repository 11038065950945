import notifSystem from '../notifSystem';
import {
  AS
} from '@lba-dev/package.local-globals/categories';
import {
  R_FOR
} from '@lba-dev/package.local-globals/remStatus';
import {
  I_DVG,
  I_APR,
  I_VRF,
} from '@lba-dev/package.local-globals/iStatus';
import { D_NOT } from '@lba-dev/package.local-globals/deStatus';
import { checkInfosClient } from '../utils/checkInputs';
import { List, Map } from 'immutable';
import { openCaldeo } from './caldeo';
import {
  PROPRIETAIRE, AUTRE
} from '@lba-dev/package.local-globals/statusClient';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import { calculateAllPrices } from '@lba-dev/package.local-globals/formatPrice';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import {
  checkIfMobileNumber,
} from '@lba-dev/package.local-globals/verifyNumber';
import collections from '../constants/collections';
import store from '../store';
import { setDialog } from './dialog';

export const openDialogCFI = (cb) => store.dispatch(
  setDialog(
    ({
      name: 'ConfirmDialog',
      open: true,
      hideClose: true,
      dialogProps: {
        title: 'Attention',
      },
      contentProps: {
        defaultText: {
          subtitle: 'Vous êtes sur le point d\'envoyer une facture en CFI,' +
      ' la facturation à distance n\'est pas autorisée'
        },
      },
      actions: [
        {
          children: 'annuler',
          color: 'secondary',
          onClick: (data, close) => close(),
        },
        {
          children: 'Continuer',
          color: 'primary',
          onClick: cb,
        }
      ],
    })
  ));

export const sendVerification = (merge, user) => {
  const priceArtisan = merge.get('priceArtisan');
  const remStatus = merge.getIn(['remunerationArtisan', 'status']);
  if (!remStatus) {
    return notifSystem.warning(
      'Veuillez attendre le chargement',
      'de la liste d\'artisan'
    );
  }
  if (
    merge.get('categorie') !== AS &&
    merge.get('status') !== I_DVG &&
    remStatus === R_FOR
  ) {
    if (!priceArtisan) {
      return notifSystem.error(
        'Informations Intervention',
        'Veuillez indiquer le prix validé par l\'artisan'
      );
    }
    const price = merge.get('finalPrice', merge.get('announcedPrice'), 0);
    const percent = merge.get('supplies', new List()).size ? 60 : 50;
    const checkPrice = price * (percent / 100);
    if (priceArtisan >= checkPrice &&
      !permit(user, { key: 'resendInstructions' })) {
      return notifSystem.error(
        'Informations Intervention',
        `Le prix artisan ne doit pas excéder ${percent}% du prix final.`
      );
    }
  }
  if (merge.getIn(['login', 'priseEnCharge'])) {
    return notifSystem.error(
      'Informations Intervention',
      'L\'envoi de cette intervention a été bloqué par le support'
    );
  }
  return false;
};

export const checkInfosFacturation = checkInfosClient(
  [
    'name',
    'tel1',
    'email1',
    {
      key: 'address',
      keys: ['city', 'number', 'road', 'zipcode'],
    },
  ],
  [
    'Nom',
    'Téléphone 1',
    'Email 1',
    ['Ville', 'Numéro', 'Adresse', 'Code postal'],
  ]
);

export function checkProducts(data) {
  if (!(data.products
    && data.products.list
    && data.products.list.length)) {
    notifSystem.error('Produits', 'Veuillez ajouter au minimum un produit');
    return false;
  }
  return true;
}

export function checkInfosFacturations(data) {
  const errors = checkInfosFacturation(data.billing);
  if (errors.length) {
    notifSystem.error('Coordonnées de facturation',
      `${errors.join(', ')} incomplets`);
    return false;
  }
  return true;
}

export function checkPrice(price) {
  if (price < 4000) {
    notifSystem.error('Erreur', `La facture ne peut être envoyée
    car le montant est inférieur à 40 € Ht`);
    return false;
  }
  return true;
}

export function checkConditionsCFI(data) {
  const { client, billing, onSitePayment } = data;
  let shouldReturnTrue = false;

  if (!onSitePayment) {
    const addressClient =
    `${client.address?.number}
     ${client.address?.road}
      ${client.address?.zipcode}`;

    const addressBilling =
    `${billing.address?.number}
     ${billing.address?.road} 
     ${billing.address?.zipcode}`;

    const isSameNameAndFirstName =
    (client.firstname === billing.firstname && client.name === billing.name);
    const isSameAddress = (addressClient === addressBilling);

    const isValidClientStatus = [PROPRIETAIRE, AUTRE]
      .includes(billing.clientStatus);

    shouldReturnTrue = isValidClientStatus
    && (isSameNameAndFirstName || isSameAddress);
  }

  return shouldReturnTrue;
}

export const checkAwaitingBillRequirements = (
  data,
  price = data.finalPrice,
  type = collections.interventions
) =>
  (data.onSitePayment || checkInfosFacturations(data))
  && checkProducts(data) &&
  (type !== collections.interventions || checkPrice(price));


export function checkPaymentMethod(inter) {
  if (inter.onSitePayment === true &&
    (inter.status !== I_DVG && !~inter.paymentMethod)) {
    notifSystem.error('Erreur', 'Veuillez sélectionner un mode de réglement.');
    return false;
  }
  return true;
}

export const checkFactIsSent = (merge) => {
  if (
    merge.get('status') === I_VRF &&
    !merge.get('onSitePayment') &&
    !(
      checkAwaitingBillRequirements(merge.toJS()) &&
      merge.hasIn(['date', 'envoiFacture'])
    )
  ) {
    notifSystem.error('Erreur', 'Veuillez envoyer la facture');
    return false;
  }
  return true;
};

export const removeGarbageProperties = (state = new Map()) =>
  state.withMutations(mutableState =>
    mutableState.update('products', new Map(), e =>
      e.withMutations(e =>
        e
          .update('list', new List(), l => l.map(p => p.delete('id')))
          .delete('index')
      )
    )
  );

export const checkDailyBlocked = (status, isBlocked) => {
  if ((!status || status === I_APR) && isBlocked) {
    notifSystem.error('Erreur', 'L\'artisan a été désélectionné.');
    return true;
  }
  return false;
};

export const addGarbageProperties = (state = new Map()) =>
  state.withMutations(mutableState =>
    mutableState.update('products', new Map(), e =>
      e.withMutations(e =>
        e
          .update('list', new List(), l => l.map((p, id) => p.set('id', id)))
          .set('index', e.get('list', new List()).size)
          .set('tva', state.getIn(['products', 'tva'], 10))
      )
    )
  );

export const checkNotation = (
  {
    onSitePayment: currentonSitePayment = null,
    notation,
    billing = {},
    date = {},
  },
  { onSitePayment, isRegle, remainCaldeo, billing: billingOrginal }
) => {
  onSitePayment =
    currentonSitePayment !== null ? currentonSitePayment : onSitePayment;
  const conditionNotation = notation
    && dayjs().diff(date.verifNotation, 'day') <= 7
    && ((notation.client
      && notation.client.note > 1
      && !notation.client.commentaire)
      || (notation.artisan
        && notation.artisan.note > 1
        && !notation.artisan.commentaire));

  const conditionBilling =
    (notation &&
      dayjs().diff(date.verifNotation, 'day') <= 7 &&
      billing.noContact &&
      !date.billingContact) ||
    isRegle ||
    onSitePayment ||
    remainCaldeo ||
    billing.noContact ||
    date.billingContact ||
    (billingOrginal && billingOrginal.grandCompte) ||
    (billingOrginal && billingOrginal.ratingEnable);
  if (!notation || (!conditionNotation && conditionBilling)) {
    return true;
  }
  notifSystem.error(
    'Erreur',
    !conditionBilling
      ? 'Merci de contacter le payeur ou d\'ajouter une date'
      : 'Commentaire obligatoire pour la notation'
  );
  return false;
};

export const checkCaldeo = (merge, type, cond = {}) =>
  merge.getIn(['combination', 'enableCaldeo']) &&
  merge.getIn(['client', 'civility']) !== 'Société' &&
  (merge.get('onSitePayment') ||
  merge.getIn(['billing', 'clientStatus']) === PROPRIETAIRE ||
  !merge.getIn(['billing', 'clientStatus'])) &&
  (cond.showButton || !merge.get('noHelp')) &&
  (type === 'devis' || (type === 'interventions' &&
    merge.getIn(['createdFrom', 'collection']) === 'devis'));

export const checkAndOpenCaldeo = (merge, props) => {
  if (merge.get('isCaldeo', false) &&
  !merge.hasIn(['login', 'fillCaldeo'])) {
    merge.set('ceeAmount', null);
    return openCaldeo(merge, props);
  }
  return props.cb();
};

export const checkIsMobileNumber = (data) => {
  const tels = [1, 2, 3].map(e => data.getIn(['client', `tel${e}`]));
  if (tels.find(e => checkIfMobileNumber(e))) {
    return true;
  }
  notifSystem.error(
    'Attention',
    'Veuiller saisir un numero mobile (06, 07 ou mobile étranger)).'
  );
};

export const checkAdvance = (merge, newAdvance = 0) => {
  const newAdv = newAdvance / 100;
  const { price } = calculateAllPrices(
    merge.products,
    merge.finalPrice || merge.annoucedPrice || 0
  );
  if (newAdv < 0 || newAdv > price) {
    notifSystem.error('Erreur',
      'L\'acompte est incorrect');
    return false;
  }
  return true;
};

export const checkGeoKey = (data) => {
  if (
    data.client && data.client.address &&
    data.client.address.location &&
    data.client.address.location.coordinates &&
    !data.client.address.location.coordinates.length
  ) {
    notifSystem.error(
      'Adresse Invalide',
      'Veuillez modifier l\'adresse depuis la barre de recherche'
    );
    return false;
  }
  return true;
};

export const verifOnCancel = (inter, user) => {
  const prixFinal = inter.finalPrice;
  const errors = [];
  if (
    !permit(user, { key: 'blockInterPriceZero' }) &&
      inter.date.envoi &&
      +prixFinal <= 0
  ) {
    errors.push('Le prix final HT doit être supérieur à 0');
  }
  if (errors.length > 0) {
    errors.forEach((error) =>
      notifSystem.error('Informations Intervention', error)
    );
    return false;
  }
  return true;
};

export const disableADemarcher = (props) => {
  const { merge, isSAV, original, user } = props;
  const dateToVerify = ['date', isSAV ? 'savDate' : 'intervention'];
  return (merge.hasIn(dateToVerify) &&
    dayjs(merge.getIn(dateToVerify))
      .isBefore()) ||
    (!isSAV && ![I_APR].includes(
      merge.get('status'))) ||
    (
      original.get('aDemarcher') !== D_NOT &&
      !permit(user, { key: 'chefPart' })
    );
};


export const displayVerifButtonOnMAJ = ({ user, selected = {} }) => {
  if (
    (selected.login || {}).demandeUpdate &&
    selected.date.demandeUpdate &&
    selected.proposition
  ) {
    const diffHours = dayjs().diff(selected.date.demandeUpdate, 'hour');
    return (
      permit(user, { key: 'commercialAdminAcces' }) ||
      selected.login.demandeUpdate === user._id ||
      diffHours > 2
    );
  }
  return true;
};

export const checkInfosInterExternal = (merge) => {
  const errors = [];

  if (!merge.infoDesc || !merge.infoDesc.desc) {
    errors.push('Veuillez sélectionner le diagnostic correspondant');
  }
  if (!merge.categorie) {
    errors.push('Veuillez ajouter une catégorie');
  }
  if (errors.length > 0) {
    errors.forEach((error) =>
      notifSystem.error('Informations Intervention', error)
    );
    return false;
  }

  return true;
}
