/* eslint-disable max-lines */
import React, { Component } from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import store from '../../store';
import ws from '../../ws';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import { withStyles } from 'tss-react/mui';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import Input from '@mui/material/Input';
import { verifyToken, sendToken } from '../../actions/list';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListContextMenu from './ListContextMenu';
import {
  Header,
  TableBody,
  TableFoot,
  TableHeader
} from './Components';
import collections from '../../constants/collections';
import {
  exportList,
  formatHeaderAndFileFields
} from '../../actions/exportFunctions';
import { setDialog, closeDialog } from '../../actions/dialog';
import { withMediaQuery } from '../../hoc';

const callbackCheck = [
  'substatus',
  'level',
  'artisanStatus',
  'categorie',
  'companyName',
  'categories',
  'currentArtisan.subStatus',
  'billing.grandCompte',
  '_dossier',
  'remuneration',
  'source',
  'sStatus',
  'iStatus',
  'debriefUser',
  'equipeAreasArtisan',
  'equipeAreasClient',
  'equipeAreasCandidats',
  'savStatus',
  'dStatus',
  'firstCall',
  'appel',
  'service',
  'choice',
  'sChoice',
  'client.zipCode'
];


const styles = () => ({
  inputPadding: {
    padding: '10px 0',
  },
  inputBGColor: {
    background: 'white',
  },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    flex: '0 0 auto',
  },
  table: {
    width: '100%',
    overflow: 'hidden',
  },
  card: {
    overflowX: 'auto',
  },
  th: {
    fontWeight: 'bold',
  },
  resetButtonOption: {
    borderRadius: 0,
    marginTop: 25,
  },
  mobilHeader: {
    background: 'white',
    padding: 20,
    marginBottom: 20,
  }
});
class List extends Component {
  state = {
    isOpen: -1,
    dialog: null,
    dialogOpen: false,
    callback: null,
    dialogText: '',
    data: {},
    previewType: '',
    select: [],
    open: false,
    allSelect: false,
    valueSelectSearchCatMobile: 'Filtres',
    valueSearchFilter: '',
    filter: {}
  }

  componentDidMount() {
    if (this.props.listen) {
      ws.on(`new_${this.props.listen}`, this.updatePage);
      ws.on(`update_${this.props.listen}`, this.wsUpdate);
      ws.on(`update_${this.props.listen}_all`, this.updatePage);
    }
  }

  componentWillUnmount() {
    if (this.props.listen) {
      ws.removeEventListener(`new_${this.props.listen}`, this.updatePage);
      ws.removeEventListener(`update_${this.props.listen}`, this.wsUpdate);
      ws.removeEventListener(
        `update_${this.props.listen}_all`,
        this.updatePage
      );
    }
  }

  setDialog = (dialog, open, text, cb) => {
    this.setState({
      dialog: dialog,
      dialogOpen: open,
      dialogText: text,
      callback: cb
    });
  }

  wsUpdate = (data) => {
    const id = data && data instanceof Object ? data._id || data.id : data;
    if (
      this.props.parent.list &&
      this.props.parent.list.findIndex(
        (elem) => elem.id === id || elem._id === id
      ) > -1
    ) {
      this.props.updateUnique(id);
    }
  }

  updateRows = (event) => {
    this.props.updateTable(event.target.value, this.props.parent.page,
      this.props.parent.filter);
  }

  updatePage = (event, page) => {
    this.props.updateTable(this.props.parent.display,
      page >= 0 ? page : this.props.parent.page,
      this.props.parent.filter);
  }

  open = (elem) => {
    this.setState({
      isOpen: this.state.isOpen === elem.elem.id ? -1 : elem.elem.id
    });
  }

  reload = () => {
    this.props.updateTable(this.props.parent.display,
      this.props.parent.page, this.props.parent.filter);
  }

  setSelected = (selected) => {
    this.setState({
      selected
    });
  }

  ifElemExists = (elem, name, value) => {
    for (let i = 0; elem[i]; i++) {
      if (elem[i][name] !== undefined) {
        elem[i][name] = value;
        return elem;
      }
    }
    elem.push({ [name]: value });
    return elem;
  }

  computeMaxMinFilter = value => {
    if (((/\d+-\d+/)).test(value)) {
      const values = value.split('-');

      return { $gte: Number(values[0]), $lte: Number(values[1]) };
    }
    if (((/\+\d+/)).test(value)) {
      return { $gte: Number(value.slice(1)) };
    }
    if (((/-\d+/)).test(value)) {
      return { $lte: Number(value.slice(1)) };
    }
    return !isNaN(value) ? Number(value) : value.toLowerCase();
  }

  filterBySign = value => {
    if (((/^\+/)).test(value)) {
      return { $gte: Number(value.replace('+', '')) * 100 };
    }
    else if (((/^-/)).test(value)) {
      return { $lte: Number(value) * 100 * -1 };
    }
    return { $eq: Number(value) * 100 };
  }

  updateFilter = (event) => {
    let {
      keys,
      checkType,
      functionType,
      filter: parentFilter,
    } = this.props.parent;
    if (event.key === 'Enter') {
      let filter;
      let elem = parentFilter['$and'] || [];

      if (~event.target.name.indexOf(',')) {
        let curr = [];
        let filters = JSON.parse(event.target.name);

        filters.forEach(e => {
          const functionCallBack = functionType
            .find(f => f.type === e);
          if (callbackCheck.includes(e) && functionCallBack) {
            curr = [functionCallBack.callback(event.target.value)];
          } else if (event.target.value && e) {
            curr.push({
              [e]: {
                $regex: `^${event.target.value.toUpperCase().escapeRegExp()}`,
                $options: 'i'
              },
            });
          }
        });
        elem = elem.filter(e => e['$or'] && curr.length
          ? Object.keys(e['$or'][0] || [])[0] !== Object.keys(curr[0] || [])[0]
          : e
        );
        if (curr.length) {
          elem.push({ $or: curr });
        }
        if (elem.length && curr.length) {
          elem = { $and: elem };
        } else {
          elem = {};
          delete parentFilter['$and'];
        }
        filter = {
          ...parentFilter,
          ...elem,
        };
      }
      else if (!event.target.value) {
        let deletedKey = false;
        const index = keys.indexOf(event.target.name);
        const elemType = ~index && functionType
          .find(e => e.type === checkType[index]);
        const fieldsToReset = (elemType && elemType.fieldsToReset) ||
          [event.target.name];

        elem.forEach((e, i) => {
          if (fieldsToReset.some(name => name in e)) {
            deletedKey = true;
            elem.splice(i, 1);
          }
        });
        filter = parentFilter;
        if (deletedKey) {
          delete filter['$and'];
          if (elem.length > 0) {
            filter = {
              ...parentFilter,
              $and: elem,
            };
          }
        }
      }
      else {
        keys.forEach((e, i) => {
          if (e === event.target.name) {
            if (['number', 'status'].includes(checkType[i])) {
              elem = this.ifElemExists(
                elem,
                event.target.name,
                this.computeMaxMinFilter(event.target.value)
              );
            } else if (checkType[i] === 'prix') {
              elem = this.ifElemExists(
                elem,
                event.target.name,
                this.filterBySign(event.target.value)
              );
            } else if (checkType[i] === 'users') {
              const user = store
                .getState()
                .users
                .filter(e =>
                  e.login
                    .toUpperCase()
                    .match(`^${event.target.value.toUpperCase()}`)
                )
                .map(e => e._id);
              if (user) {
                elem = this.ifElemExists(
                  elem,
                  event.target.name,
                  { $in: user.toArray() }
                );
              }
            } else if (checkType[i] === 'date') {
              let type, date;
              let split = event.target.value.split('/');
              if (split.length === 1) {
                type = 'year';
                date = dayjs(event.target.value, 'YYYY');
              } else if (split.length === 2) {
                type = 'month';
                date = dayjs(event.target.value, 'MM/YYYY');
              } else {
                type = 'day';
                date = dayjs(event.target.value, 'DD/MM/YYYY');
              }
              if (date.isValid()) {
                const start = date.startOf(type);
                const end = date.clone().endOf(type);
                elem = this.ifElemExists(elem, event.target.name, {
                  $gte: start.toString(),
                  $lt: end.toString(),
                });
              }
            } else if (callbackCheck.includes(checkType[i])) {
              functionType.forEach(e => {
                if (e.type === checkType[i]) {
                  const data = e.callback(event.target.value);
                  if (data) {
                    Object.keys(data).forEach(e => {
                      const findData = elem.some(s => s[e]);
                      if (findData) {
                        elem = elem.map(s => s[e] ? data : s);
                      } else {
                        elem.push(data);
                      }
                    });
                  }
                }
              });
            } else {
              elem = this.ifElemExists(
                elem,
                event.target.name,
                event.target.value
              );
            }
          }
        });
        if (elem.length) {
          filter = {
            ...parentFilter,
            $and: elem
          };
        } else {
          filter = parentFilter;
        }
      }
      this.setState({ filter });
      this.props.updateTable(
        this.props.parent.display,
        this.props.parent.page,
        filter,
        true
      );
      event.preventDefault();
    }
  }

  accessCSV = () => {
    store.dispatch(
      setDialog({
        name: 'SendTokenDialog',
        open: true,
        dialogProps: {
          title: 'Un code est nécessaire pour télècharger le CSV',
        },
        hideClose: true,
        actions: [
          {
            children: 'Demander un code',
            color: 'primary',
            hideButton: ({ sent = false }) => sent,
            onClick: ({ sent }) => {
              sendToken(sent);
            }
          },
          {
            children: 'Valider',
            color: 'primary',
            disabled: ({ token = '' }) => token.length < 4,
            hideButton: ({ sent = false }) => !sent,
            onClick: ({ token }) => {
              verifyToken( token, this.getCSVfile);
            }
          },
          {
            children: 'Renvoyer un code',
            color: 'primary',
            disabled: ({ disabled = false }) => disabled,
            hideButton: ({ sent = false }) => !sent,
            onClick: ({ sent }) => {
              sendToken(sent);
            }
          },
          {
            children: 'Annuler',
            color: 'secondary',
            onClick: (props, close) => {
              close();
            }
          }
        ]
      })
    );
  }

  getCSVfile = async () => {
    const { name = '' } = this.props;
    const { keys, table, filter: parentFilter } = this.props.parent;
    const filter = { ...this.state.filter, ...parentFilter };
    const oFields = keys.reduce((a, b) => {
      if (b instanceof Array) {
        const subObj = b.reduce((y, z) => z ? ({ ...y, [z]: 1 }) : y, {});
        Object.assign(a, subObj);
        return a;
      }
      return ({ ...a, [b]: 1 });
    }, {});
    const collection = Object.keys(collections)
      .find(e => (e.toLocaleLowerCase()).includes(name.toLowerCase()));
    if (collection) {
      store.dispatch(setDialog({
        name: 'LoadingDialog',
        open: true,
        dialogProps: { title: 'En cours' },
      }));
      const oFileFields = keys;
      const oFileHeader = [...Object.keys(table)];
      const {
        fileFields = oFileFields, fileHeader = oFileHeader, fields = oFields
      } = formatHeaderAndFileFields(oFileFields, oFileHeader, oFields)[
        collection] || {};
      await exportList(collection,
        {
          query: JSON.stringify(filter),
          field: JSON.stringify(fields),
          fileHeader: JSON.stringify(fileHeader),
          originalFields: JSON.stringify(fileFields)
        },
        fileHeader);
      store.dispatch(closeDialog());
    }
  }

  isSelected = (id) => {
    const { select } = this.state;
    let push = true;
    for (let i in select) {
      if (select[i] === id) {
        push = false;
        select.splice(i, 1);
      }
    }
    if (push) {
      select.push(id);
    }
    this.setState({ select });
  }

  handlerSelectCategoryFilterMobile = (e) => {
    this.setState({
      valueSelectSearchCatMobile: e.target.value,
      valueSearchFilter: ''
    });
  }

  handlerValueSearchFilter = (e) => {
    this.setState({ valueSearchFilter: e.target.value });
  }

  handlerResetFilter = (e, props) => {
    props.updateTable(props.parent.display,
      props.parent.page, {}, true);
    this.setState({
      valueSearchFilter: ''
    });
    e.preventDefault();
  }

  handlerSendSearch = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.updateFilter(e);
    }
  }

  viewsSearchMobile = (table, keys) => {
    const { valueSelectSearchCatMobile, valueSearchFilter } = this.state;
    const { classes } = this.props;
    const elem = keys[Object.keys(table).indexOf(valueSelectSearchCatMobile)];
    return (
      <form className={classes.mobilHeader}>
        <Select
          fullWidth
          value={valueSelectSearchCatMobile}
          onChange={this.handlerSelectCategoryFilterMobile}
          className={classNames(
            classes.inputBGColor,
            classes.inputPadding,
            classes.fullWidth,
          )}
        >
          <MenuItem
            selected={true}
            disabled
            key="Filtres"
            value={valueSelectSearchCatMobile}
          >
            Filtres
          </MenuItem>
          {
            Object.keys(table).map(c =>
              <MenuItem key={c} value={c}>{c}</MenuItem>
            )
          }
        </Select>
        {
          valueSelectSearchCatMobile !== 'Filtres' &&
          <React.Fragment>
            <Input
              name={typeof elem === 'object' ? JSON.stringify(elem) : elem}
              value={valueSearchFilter}
              className={classNames(
                classes.inputBGColor,
                classes.inputPadding,
                classes.fullWidth,
              )}
              disableUnderline={false}
              multiline={false}
              placeholder="Recherche..."
              fullWidth={true}
              onChange={this.handlerValueSearchFilter}
              onKeyDown={this.handlerSendSearch}
            />
            <Button
              variant="contained"
              color="secondary"
              className={`${classes.fullWidth} ${classes.resetButtonOption}`}
              onClick={(e) => this.handlerResetFilter(e, this.props)}
              children="Réinitiliser"
            />
          </React.Fragment>
        }
      </form>
    );
  }

  render() {
    const {
      contextMenu,
      classes,
      menu,
      actions,
      updateTable,
      row,
      name,
      users,
      idMenu,
      userId,
      small,
      localUpdate,
      handlerFilterByColumns,
      handleChange,
      radioValue,
      checkClientNumber,
      filteredByColumn,
      listen,
      user,
      title,
      selectedForCampaign,
      setSelectedForCampaign,
    } = this.props;
    const {
      display,
      page,
      filter,
      table,
      keys,
      checkType,
      count,
      list,
      tableData,
    } = this.props.parent;
    const {
      isOpen,
      selected,
      dialogOpen,
      callback,
      data,
      dialogText,
      allSelect
    } = this.state;
    return (
      <div>
        {this.props.isDown.md && this.viewsSearchMobile(table, keys)}
        <Card className={classNames('innerBorder', classes.card, 'cards')}>
          {filter && (
            <Header
              updateTable={updateTable}
              display={display}
              title={title}
              page={page}
              filter={filter}
              user={user}
              getCSV={this.accessCSV}
            />
          )}
          <Table>
            <TableHeader
              radioValue={radioValue}
              handleChange={handleChange}
              table={table}
              keys={keys}
              checkType={checkType}
              setFilter={menu}
              updateFilter={this.updateFilter}
              handlerFilterByColumns={handlerFilterByColumns}
              filteredByColumn={filteredByColumn}
              name={name}
            />
            <TableBody
              listen={listen}
              setDialog={this.setDialog}
              checkClientNumber={checkClientNumber}
              small={small}
              table={table}
              idMenu={idMenu ? idMenu : 'SIMPLE'}
              keys={keys}
              checkType={checkType}
              list={list}
              tableData={tableData}
              setSelected={this.setSelected}
              isSelected={this.isSelected}
              allSelect={allSelect}
              localUpdate={localUpdate}
              open={this.open}
              isOpen={isOpen}
              menu={menu}
              row={row}
              user={user}
              users={users}
              userId={userId}
              name={name}
              setSelectedForCampaign={setSelectedForCampaign}
              selectedForCampaign={selectedForCampaign}
            />
            <TableFoot
              display={display}
              page={page}
              count={count}
              updateRows={this.updateRows}
              updatePage={this.updatePage}
            />
          </Table>
          {!contextMenu && (
            <ListContextMenu
              actions={actions}
              idMenu={idMenu ? idMenu : 'SIMPLE'}
              selected={selected}
              setDialog={this.setDialog}
              updateTable={updateTable}
              display={display}
              page={page}
              filter={filter}
              tableData={tableData}
              selectedForCampaign={selectedForCampaign}
              setSelectedForCampaign={setSelectedForCampaign}
            />
          )}
          {(
            this.state.dialog && (
              <this.state.dialog
                open={dialogOpen}
                callback={callback}
                data={data}
                defaultText={dialogText}
              />
            )
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ users, userId }) => ({
  userId, users,
  user: users.find(e => e._id === userId)
});

export default compose(
  connect(mapStateToProps),
  withMediaQuery()
)(withStyles(List, styles))
