import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';

const styles = {
  line: {
    lineHeight: 'normal'
  }
};

const DemandeMenuInfo = ({
  date,
  userId,
  text,
  osId,
  devisId,
  classes,
  findUserLogin
}) => !!date &&
  <Typography
    variant="body1"
    className={classes.lineHeight}
  >
    {dayjs(date).format('LLL')}
    {userId ? ` - ${findUserLogin(userId)}` : ''}{` : ${text}  `}
    {(osId &&
      <React.Fragment>
        <Link to={`/intervention/${osId}`}>
          {osId}
        </Link>
      </React.Fragment>
    )
    || (devisId &&
      <React.Fragment>
        <Link to={`/devis/${devisId}`}>
          {devisId}
        </Link>
      </React.Fragment>
    )}
  </Typography>;

export default withStyles(DemandeMenuInfo, styles);
